import { ConfigCatPlugin } from "configcat-vue"
import { createPinia } from "pinia"
import { createApp, nextTick } from "vue"
import { apolloProvider } from "~/config/apollo"
import { basePageTitle, configCatSdkKey } from "~/config/environment"
import FloatingVueWithOptions from "~/config/floating-vue"
import useSentry from "~/config/sentry"
import { logPageView } from "~/lib/logUsage"
import authGuard from "~/lib/router/authGuard"

import App from "./App.vue"
import router from "./router"

// Fonts from unplugin-fonts; import error is a false positive
// eslint-disable-next-line import-x/no-unresolved
import "unfonts.css"
// Tailwind
import "~/assets/css/main.css"
// https://floating-vue.starpad.dev/
import "floating-vue/dist/style.css"
import "~/assets/css/floating-overrides.css"

const app = createApp(App)

app.use(router)

app.use(createPinia())

// Install Sentry as early as possible, but it requires pinia, so put it after that :)
useSentry(app, router)

// Apollo options API must be installed here; composition is in App.vue
app.use(apolloProvider)

// Feature flippers
if (configCatSdkKey && configCatSdkKey !== "DISABLED") {
  app.use(ConfigCatPlugin, { sdkKey: configCatSdkKey })
}

app.use(FloatingVueWithOptions)

router.beforeEach(authGuard)

// Track visits
router.afterEach(logPageView)

router.afterEach((to) => {
  // Use nextTick to handle any asynchronous changes in the title
  nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - ${basePageTitle}` : basePageTitle
  })
})

app.mount("#app")

import { maxBy } from "es-toolkit"
import { cloudLocations as cloudStorageServices } from "~/components/File/Display/constants"

interface LastFileLocationType {
  receivedAt: Date
}

export const latestFileLocation = <T extends LastFileLocationType>(fileLocations: T[]): T => {
  if (!fileLocations?.length) throw new Error(`There are no file locations to find the first`)

  const latest = maxBy(fileLocations, (fl) =>
    fl.receivedAt instanceof Date ? fl.receivedAt.getTime() : fl.receivedAt,
  )

  if (!latest) throw new Error(`Somehow couldn't find file location for non-empty list`)

  return latest
}

interface BestNameFromFileLocationsType extends LastFileLocationType {
  storedInService: string
  originatesFromThisUser: boolean
  name: string
}

// For the best file name, use the most recent file (reflecting the most
// recent and hopefully most-recently desired name chosen), but with a
// preference for any that are from cloud storage locations (reflecting that
// this will always be a name chosen and controlled by the user, as opposed
// to incoming emails where the sender may have changed the name)
export const bestNameFromFileLocations = <T extends BestNameFromFileLocationsType>(
  fileLocations: T[],
): string => {
  if (!fileLocations?.length) throw new Error("File must have locations to derive name from")

  const cloudLocations = fileLocations.filter((fl) =>
    cloudStorageServices.includes(fl.storedInService),
  )

  const myCloudLocations = cloudLocations.filter((fl) => fl.originatesFromThisUser)

  let locationsToUse = undefined
  if (myCloudLocations.length > 0) {
    // Best name will be a cloud storage file name that the user saved
    // themselves
    locationsToUse = myCloudLocations
  } else if (cloudLocations.length > 0) {
    // Next best is a cloud storage file name that someone else created (this is
    // more future-proofing for teams)
    locationsToUse = cloudLocations
  } else {
    // Fallback on the most recent file name (not the original filename)
    locationsToUse = fileLocations
  }

  return latestFileLocation<BestNameFromFileLocationsType>(locationsToUse).name
}

import { ApolloClient, ApolloLink, type Operation } from "@apollo/client/core"
import type { NetworkError } from "@apollo/client/errors"
import { onError } from "@apollo/client/link/error"
import { createConsumer } from "@rails/actioncable"
import { captureException, withScope } from "@sentry/vue"
import { createApolloProvider } from "@vue/apollo-option"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink.js"
import apolloCache from "~/config/apollo.cache.js"
import { graphqlUrl, websocketUrl } from "~/config/environment"
import { showToast } from "~/lib/notify"
import handleTopLevelError from "~/lib/topLevelErrorHandlers"

const isNotFoundMessage = (errorMessage: string) => {
  const errorMessages = ["not found", "Couldn't find", "No object found"]
  return errorMessages.some((error) => errorMessage.includes(error))
}

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === "OperationDefinition" && operation === "subscription",
  )
}

const showNetworkError = (networkError: NetworkError, operation: Operation) => {
  // Apollo's error object is generic and joined with a catch-all type Error, so we have to do some
  // tricks to get it to let us check the results of some types of errors.
  const result = "result" in networkError && networkError.result
  const resultError = typeof result !== "string" && result?.error

  const statusCode = "statusCode" in networkError && networkError.statusCode

  if (
    networkError.message?.includes("Failed to fetch") &&
    operation.operationName === "CreateFileRecord"
  ) {
    // Although a "Failed to fetch" (net::ERR_FAILED) has a lot of possible causes like firewall
    // issues, A/V scans, or internet outages, if the user has got far enough into Recital to do
    // a file record upload, the most likely cause is that the file is stored on OneDrive and
    // currently open in another program.
    // The recommended solution from MS is to close the file:
    // https://support.microsoft.com/en-gb/office/onedrive-shows-this-file-can-t-be-synced-54f6e0d2-41d3-496a-826e-964e59333d7e
    showToast(
      "Your file could not be uploaded. If this file is stored on OneDrive and opened in another program, please close the file before trying again.",
      {
        type: "error",
      },
    )
  } else if (
    // Safari's fetch returns "Load failed", whereas firefox and chrome
    // return NetworkError when attempting to fetch resource.
    // So test for all scenarios
    networkError.message?.includes("Load failed") ||
    networkError.message?.includes("Failed to fetch") ||
    networkError.message?.includes("NetworkError when attempting to fetch resource.")
  ) {
    // Comparisons can take a long time, and we are intentionally letting that
    // happen, with notification of events via subscriptions instead. This
    // means that timeouts of comparison triggers are expected, and we don't
    // want to show an oerror for them.
    if (operation.operationName !== "TriggerComparison") {
      showToast(`Request timed out: ${operation.operationName}`, {
        type: "error",
      })
    }
  } else if (resultError === "Session expired" || statusCode === 401) {
    const currentPath = window.location.pathname + window.location.search
    showToast("Your session has expired.", {
      type: "warning",
      action: {
        text: "Login",
        url: `/login?returnUrl=${currentPath}`,
      },
    })
  } else {
    // We'd like to know everything we can about unhandled exceptions
    withScope((scope) => {
      scope.setExtra("operation", operation)
      captureException(networkError)
    })

    showToast(networkError.message || resultError, {
      type: "error",
    })
  }
}

function link() {
  // ActionCable / Apollo integration code from:
  // https://github.com/rmosolgo/graphql-ruby/blob/master/guides/javascript_client/apollo_subscriptions.md#apollo-2--actioncable

  // Adapted from RoR guide; setting a param directly is the only way to get a token to
  // ActionCable's backend :\
  // https://stackoverflow.com/questions/35501931/send-auth-token-for-authentication-to-actioncable
  // https://guides.rubyonrails.org/action_cable_overview.html#client-side-components
  // Because of that, we need to make sure to use wss in production, and to filter token param from
  // our logs
  const cable = createConsumer(websocketUrl)

  // Using createUploadLink to support file uploads in future, copied from:
  // https://github.com/Akryum/vue-cli-plugin-apollo/blob/d9fe48c61cc19db88fef4e4aa5e49b31aa0c44b7/graphql-client/src/index.js#L66
  const httpLink = createUploadLink({
    uri: graphqlUrl,
    credentials: "include",
  })

  // Intercept top-level errors: these are for the develpor
  // https://graphql-ruby.org/errors/execution_errors.html
  // https://productionreadygraphql.com/2020-08-01-guide-to-graphql-errors
  const topLevelErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors && graphQLErrors[0]) {
      const { message } = graphQLErrors[0]
      const statusCode = isNotFoundMessage(message) ? 404 : 500

      if (statusCode === 404) {
        window.location.href = `/error?statusCode=${statusCode}&message=${message}`
      } else {
        handleTopLevelError(graphQLErrors[0])
      }
    } else if (networkError) {
      showNetworkError(networkError, operation)
    }
  })

  // Intercept mutation errors and things to show users, not developers
  // https://www.apollographql.com/docs/react/api/link/introduction/#handling-a-response
  const applicationErrorLink = new ApolloLink((operation, forward) => {
    // Continue along the chain
    return forward(operation).map((data) => {
      const queryIsMutation = operation.query.definitions.some(
        (def) => "operation" in def && def.operation === "mutation",
      )

      // Show errors that happen during mutations
      if (queryIsMutation && data?.data) {
        // Errors appears within a key about the operation (e.g.
        // data.createItem.errors)
        const responses = Object.values(data.data)

        let errors = responses.map((response) => response?.errors)

        // Flatten and cut out null entries
        errors = errors.flat().filter((error) => !!error)

        // Called after server responds
        if (errors?.length > 0) {
          for (const error of errors) showToast(error, { type: "error" })
        }
      }

      return data
    })
  })

  const terminatingLink = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    httpLink,
  )

  return ApolloLink.from([topLevelErrorLink, applicationErrorLink, terminatingLink])
}

export const apolloClient = new ApolloClient({
  cache: apolloCache,
  link: link(),
  defaultOptions: {
    watchQuery: {
      // By default, use any data that's cached to show a faster response, but
      // also run the query in the background for any updates
      fetchPolicy: "cache-and-network",
    },
  },
})

// Allow access to the provider in options API
export const apolloProvider = createApolloProvider({ defaultClient: apolloClient })

import { watch } from "vue"
import { useUserInfoStore } from "~/stores/userInfo"

function identifyUserForCrisp(userInfo) {
  window.$crisp ||= []
  // Restart Crisp with session continuity enabled
  // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/session-continuity/
  window.CRISP_TOKEN_ID = userInfo.service_signatures.crisp
  window.$crisp.push(["do", "session:reset"]) // Required by Crisp after setting the token

  // Set a verified user email -- crisp overrides .push so don't try to combine pushes, who knows
  // what would happen
  // eslint-disable-next-line unicorn/no-array-push-push
  window.$crisp.push(["set", "user:email", [userInfo.email, userInfo.service_signatures.crisp]])

  if (userInfo.name) {
    window.$crisp.push(["set", "user:nickname", userInfo.name])
  }
}

function resetCrispSession() {
  window.$crisp ||= []
  // Segment manages everything for us EXCEPT resetting sessions
  // I've sent them feedback about this
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
  window.$crisp.push(["do", "session:reset"])
  return Promise.resolve()
}

const useCrisp = () => {
  // Okay so since we have Crisp connected to Segment, Segment actually takes care of loading Crisp
  // for us. That even loads up the Crisp widget.
  // But even though that loads the JS, it doesn't actually identify the user, or reset the session.
  // We have to do that manually? I don't get it.
  const userInfoStore = useUserInfoStore()

  watch(
    () => userInfoStore.data,
    (newUserInfo, oldUserInfo) => {
      if (!oldUserInfo && newUserInfo) {
        identifyUserForCrisp(newUserInfo)
      } else if (!newUserInfo && oldUserInfo) {
        resetCrispSession()
      }
    },
    { immediate: true },
  )
}

export default useCrisp
